<template>
  <div class="overflow-hidden">
    <Container class="relative z-20 pt-24 text-white lg:h-full lg:flex lg:pointer-events-none lg:pt-0 lg:items-center">
      <div class="w-full lg:flex lg:items-end">
        <AnimatedText :copy="title"
                      tag="h2"
                      :visible="textVisible"
                      class="text-4xl leading-tight sm:text-5xl sm:leading-tight lg:mr-24 lg:max-w-3xl"
        />
        <AnimatedText :copy="copy"
                      :visible="textVisible"
                      tag="p"
                      class="max-w-md mt-8 ml-auto text-2xl leading-tight sm:text-3xl lg:leading-tight lg:mt-24"
        />
      </div>
    </Container>

    <div ref="container"
         class="absolute inset-0"
    >
      <div v-for="(word, i) in tags"
           :key="i"
           class="absolute top-0 left-0 inline-block"
           :style="{ transform: `translate3d(${positions[i].x}px, ${positions[i].y}px, 0) ` }"
      >
        <div class="px-4 py-2 text-xl text-white duration-150 rounded-full select-none lg:px-8 lg:py-6 lg:text-4xl font-white cursor-grab active:cursor-grabbing"
             :class="`bg-${word.color}`"
             :style="{ transform: `translate3d(-50%, -50%, 0) rotate(${positions[i].rotation}rad)` }"
        >
          {{ word.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Matter from 'matter-js'
import { ref, watch } from 'vue'
import AnimatedText from './animated-text.vue'
import { useScreenSize } from '~/composables/use-screen-size'

const { isDesktop } = useScreenSize()

interface gravityTagsProps {
  title: string
  copy: string
  tags: {
    text: string
    color: string
  }[]
  trigger: number
}

const props = defineProps<gravityTagsProps>()

const container = ref()
const running = ref(false)
const textVisible = ref(false)

watch(
  () => props.trigger,
  trigger => {
    if (trigger > 0 && !running.value && trigger < 1) {
      running.value = true
      setup()
    }
    else if (trigger < 0 || trigger > 1.1) running.value = false
    if (trigger > 1 || trigger < 0) textVisible.value = false
    else textVisible.value = true
  }
)

// module aliases
const Engine = Matter.Engine,
  Bodies = Matter.Bodies,
  Composite = Matter.Composite,
  Composites = Matter.Composites,
  MouseConstraint = Matter.MouseConstraint,
  Mouse = Matter.Mouse
// Render = Matter.Render;

const positions = ref([])
positions.value = props.tags.map(() => {
  return { x: 0, y: 0, rotation: 0 }
})

// create an engine
let engine: Matter.Engine | undefined
let stack: Matter.Composite
let world: Matter.World
let ground: Matter.Body

const setup = () => {
  engine = Engine.create()
  world = engine.world
  stack = Composites.stack(
    isDesktop.value ? window.innerWidth / 4 : 0,
    -window.innerHeight / 1.4,
    isDesktop.value ? 3 : 2,
    positions.value.length,
    0,
    40,
    function (x: number, y: number, column: number, row: number, lastBody: Matter.Body, i: number) {
      if (i + 1 > positions.value.length) return
      const element = container.value.children[i]
      const width = element.offsetWidth
      const height = element.offsetHeight
      return Bodies.rectangle(x, y, width, height, { chamfer: { radius: height / 2 }, mass: width / 10, angle: Math.random() * 2 - 1 })
    }
  )

  // const render1 = Render.create({
  //   element: container.value,
  //   engine: engine,
  //   options: {
  //     width: container.value.offsetWidth,
  //     height: container.value.offsetHeight,
  //     showAngleIndicator: true
  //   }
  // });

  // Render.run(render1);

  // create bounds
  ground = Bodies.rectangle(window.innerWidth / 2 + 160, window.innerHeight + 40, window.innerWidth + 320, 80, {
    isStatic: true
  })

  const wallLeft = Bodies.rectangle(-80, window.innerHeight / 2, 160, window.innerHeight, { isStatic: true })
  const wallRight = Bodies.rectangle(window.innerWidth + 20, window.innerHeight / 2, 80, window.innerHeight, { isStatic: true })
  const roof = Bodies.rectangle(window.innerWidth / 2 + 160, -80, window.innerWidth + 320, 160, { isStatic: true })

  Composite.add(world, [stack, ground, wallRight, wallLeft])
  setTimeout(() => {
    Composite.add(world, roof)
  }, 2000)

  const mouse = Mouse.create(container.value)
  const options = {
    mouse: mouse
  }
  const mConstraint = MouseConstraint.create(engine, options)
  Composite.add(world, mConstraint)

  render()
}

const clear = () => {
  if (Engine) Engine.clear(engine)
  Composite.clear(world, true)
}

// const clearGround = () => {
//   Composite.remove(world, ground);
// };

const render = () => {
  if (Engine) Engine.update(engine, 20)

  positions.value = stack.bodies.map(body => {
    return { x: body.position.x, y: body.position.y, rotation: body.angle }
  })

  if (running.value) window.requestAnimationFrame(render)
  else clear()
}
</script>
